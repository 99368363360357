body {
    margin: 0;
    font-family: "Kanit", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout-content {
    padding: 16px 24px;
}

.Toastify__toast-body {
    font-family: "Kanit", sans-serif !important;
}

.firebase-emulator-warning {
    font-size: 6px;
    height: 8px;
    line-height: 8px;
}

@media screen and (max-width: 540px) {
    .ant-picker-panels {
        flex-direction: column;
    }
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
}
